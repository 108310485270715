import React from 'react';

import { Heading, Main, Paragraph, Strong } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import houseOutline from 'assets/common/House_white_CMYK.png';
import GradientBox from 'client/components/gradientBox/GradientBox';
import MetaTags from 'client/components/metaTags/MetaTags';
import { NavigationTiles } from 'client/components/navigationTiles';
import RedGradientBox from 'client/components/redGradientBox/RedGradientBox';

import { PlacingBusinessContent } from './PlacingBusiness.config';

function PlacingBusinessPage(): React.JSX.Element {
  const { pageTitle, bannerSubheader, navigationTiles, metaContent } =
    useContent<PlacingBusinessContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <GradientBox>
        <RedGradientBox
          imgSrc={houseOutline}
          imgAlt="Icon of a house"
        >
          <Heading as="h1" size="s7" marginBottom="03" inverse>
            {pageTitle}
          </Heading>
          <Paragraph>
            <Strong inverse>{bannerSubheader}</Strong>
          </Paragraph>
        </RedGradientBox>
      </GradientBox>
      <NavigationTiles
        navigationTiles={navigationTiles}
        pageTestId="placing-business-page-navigation-tiles"
        icon="right"
      />
    </Main>
  );
}

export default PlacingBusinessPage;
