import accessibility from './accessibility';
import { appLayout } from './appLayout';
import articleImprovingQualityBenefitsLandlordsAndTenants from './articleImprovingQualityBenefitsLandlordsAndTenants';
import articleLetsGetRetrofitForTheFuture from './articleLetsGetRetrofitForTheFuture';
import articles from './articles';
import bdmSearch from './bdmSearch';
import buyToLetArticle from './buyToLetChangedBMSolutionsChangedWithIt';
import calculators from './calculators';
import complaints from './complaints';
import contactUs from './contactUs';
import cookies from './cookies';
import disclaimer from './disclaimer';
import documentUpload from './documentUpload';
import educationalHub from './educationalHub';
import epcRegulations from './epcRegulations';
import epcStepByStepGuide from './epcStepByStepGuide';
import existingCustomers from './existingCustomers';
import fees from './fees';
import footer from './footer';
import freeConveyancingService from './freeConveyancingService';
import furtherAdvances from './furtherAdvances';
import grantsAndSchemes from './grantsAndSchemes';
import home from './home';
import intermediaryServiceSupport from './intermediaryServiceSupport';
import landlordRetrofitTool from './landlordRetrofitTool';
import lowerRatesForEnergyEfficientHomes from './lowerRatesForEnergyEfficientHomes';
import navigationMenu from './navigationMenu';
import newBusinessEnquiries from './newBusinessEnquiries';
import notfound from './notFound';
import ourCriteria from './ourCriteria';
import placingBusiness from './placingBusiness';
import porting from './porting';
import privacy from './privacy';
import productTransfers from './productTransfers';
import rateChangeCalculator from './rateChangeCalculator';
import register from './register';
import resources from './resources';
import security from './security';
import sitemap from './sitemap';
import submittedMortgageApplications from './submittedMortgageApplications';
import submittingBtlOrLtbMortgageApplication from './submittingBtlOrLtbMortgageApplication';
import sustainabilityHub from './sustainabilityHub';
import termsAndConditions from './termsAndConditions';
import transferOrPurchaseOfEquity from './transferOrPurchaseOfEquity';
import { AppContent } from '../AppContent';

// #pragma insert-content-value-import

const content: AppContent = {
  notfound,
  home,
  appLayout,
  resources,
  footer,
  navigationMenu,
  disclaimer,
  existingCustomers,
  contactUs,
  ourCriteria,
  sustainabilityHub,
  intermediaryServiceSupport,
  complaints,
  epcRegulations,
  lowerRatesForEnergyEfficientHomes,
  grantsAndSchemes,
  newBusinessEnquiries,
  landlordRetrofitTool,
  educationalHub,
  submittedMortgageApplications,
  epcStepByStepGuide,
  freeConveyancingService,
  placingBusiness,
  submittingBtlOrLtbMortgageApplication,
  productTransfers,
  furtherAdvances,
  transferOrPurchaseOfEquity,
  calculators,
  termsAndConditions,
  porting,
  fees,
  documentUpload,
  accessibility,
  cookies,
  security,
  articles,
  rateChangeCalculator,
  register,
  privacy,
  sitemap,
  articleImprovingQualityBenefitsLandlordsAndTenants,
  bdmSearch,
  articleLetsGetRetrofitForTheFuture,
  buyToLetArticle,

  // #pragma insert-content-value
};

export default content;
