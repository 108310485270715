/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Container,
  Grid,
  GridItem,
  Heading,
  Link,
  List,
  ListItem,
  Main,
  Paragraph,
  Strong,
} from '@constellation/core';
import { ChevronUp } from '@constellation/core/icons';
import { useContent } from '@interstellar/react-app-content';
import clipboard from 'assets/common/clipboard.png';
import BasicTable from 'client/components/basicTable/BasicTable';
import GradientBox from 'client/components/gradientBox/GradientBox';
import { InfoPanel } from 'client/components/infoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';
import RedGradientBox from 'client/components/redGradientBox/RedGradientBox';
import { TaggedAccordion } from 'client/components/taggedAccordion/TaggedAccordion';
import { logTealiumButtonClickEvent } from 'client/tealium/functions/logTealiumButtonClickEvent';
import testIds from 'client/testIds';

import { OurCriteriaContent } from './OurCriteria.config';
import { StyledDiv } from './OurCriteria.styled';

export default function OurCriteriaPage() {
  const {
    heading,
    introText,
    application,
    jumpToASection,
    customer,
    income,
    lending,
    topSlicing,
    property,
    existingCustomer,
    metaContent,
  } = useContent<OurCriteriaContent>();

  const renderLinkItems = (linkItems) =>
    linkItems.map((linkItem) => (
      <Paragraph key={linkItem.label}>
        <Link href={linkItem.url}>{linkItem.label}</Link>
      </Paragraph>
    ));
  const renderTextItems = (textItemsList) =>
    textItemsList.map((item) => (
      <Paragraph key={item.text}>{item.text}</Paragraph>
    ));
  const renderListItems = (itemslist) =>
    itemslist.map((listItem) => <ListItem key={listItem}>{listItem}</ListItem>);

  const backToTop=(id:string)=>(
    <StyledDiv>
      <Link href={`#${jumpToASection.id}`} data-testid={`${id}_backToTop`}>
        <Strong color='brand'>Back to top<ChevronUp color="default1" size='s3'/></Strong>
      </Link>
    </StyledDiv>
  )
  return (
    <Main>
      <MetaTags metaContent={metaContent} />
      <GradientBox>
        <RedGradientBox
          imgSrc={clipboard}
          imgAlt="Icon of a notepad with a checklist"
        >
          <Heading as="h1" size="s7" marginBottom="03" data-testid={testIds.ourCriteria.heading} inverse>
            {heading}
          </Heading>
          <Paragraph>
            <Strong inverse>
              {introText}
            </Strong>
          </Paragraph>
        </RedGradientBox>
      </GradientBox>
      <div data-testid="jump-to-a-section" >
        <Heading size="s5" marginBottom="05" as="h2" id={jumpToASection.id}>
          {jumpToASection.title}
        </Heading>
        <Grid>
          {jumpToASection.links.map((linkItem) => (
            <GridItem sm={6} md={4} lg={4} key={linkItem.label}>
              <Paragraph size="s2" marginBottom="02">
                <Link
                  weight="bold"
                  href={`#${linkItem.url}`}
                  onClick={() => {
                    logTealiumButtonClickEvent({
                      label: `link/${linkItem.label}`,
                    });
                  }}
                >
                  {linkItem.label}
                </Link>
              </Paragraph>
            </GridItem>
          ))}
        </Grid>
      </div>
      <Heading
        size="s5"
        marginBottom="05"
        marginTop="05"
        as="h2"
        id={application.id}
      >
        {application.title}
      </Heading>
      <InfoPanel
        infoPanel={application.infoPanel}
        pageTestIds={testIds.ourCriteria}
      />
      {backToTop(application.id)}
      <Heading
        id={customer.id}
        size="s5"
        marginBottom="05"
        marginTop="05"
        as="h2"
      >
        {customer.title}
      </Heading>

      <TaggedAccordion
        label={customer.accordion1.title}
        id={customer.accordion1.id}
        variation="inpage1"
      >
        {renderTextItems(customer.accordion1.textItems)}
        <Paragraph>
          <Strong>{customer.accordion1.boldText}</Strong>
        </Paragraph>
        <Paragraph>{customer.accordion1.text}</Paragraph>
        <Paragraph>{customer.accordion1.text2}</Paragraph>
        <Paragraph>
          <Strong>{customer.accordion1.boldText2}</Strong>
        </Paragraph>

        <List>{renderListItems(customer.accordion1.list)}</List>
        <Paragraph>{customer.accordion1.text3}</Paragraph>
        <Paragraph>
          <Strong>{customer.accordion1.boldText3}</Strong>
        </Paragraph>
        <Paragraph>{customer.accordion1.text4}</Paragraph>
        <Paragraph>{customer.accordion1.text5}</Paragraph>
        <Paragraph>
          <Strong>{customer.accordion1.boldText4}</Strong>
        </Paragraph>
        <BasicTable {...customer.accordion1.table} />
      </TaggedAccordion>
      <TaggedAccordion
        label={customer.accordion2.title}
        id={customer.accordion2.id}
        variation="inpage1"
      >
        <Paragraph>{customer.accordion2.text}</Paragraph>
      </TaggedAccordion>

      <TaggedAccordion
        label={customer.accordion3.title}
        id={customer.accordion3.id}
        variation="inpage1"
      >
        {renderTextItems(customer.accordion3.textItems)}
        <Link href={customer.accordion3.link.url}>
          {customer.accordion3.link.label}
        </Link>
      </TaggedAccordion>
      <TaggedAccordion
        label={customer.accordion4.title}
        id={customer.accordion4.id}
        variation="inpage1"
      >
        {renderTextItems(customer.accordion4.textItems)}
        <Paragraph>
          <Strong>{customer.accordion4.title2}</Strong>
        </Paragraph>

        <Paragraph>{customer.accordion4.text1}</Paragraph>
        <List>{renderListItems(customer.accordion4.list)}</List>
        {renderTextItems(customer.accordion4.textItems2)}
        <List>{renderListItems(customer.accordion4.list2)}</List>
      </TaggedAccordion>

      <TaggedAccordion
        label={customer.accordion5.title}
        id={customer.accordion5.id}
        variation="inpage1"
      >
        <Paragraph>{customer.accordion5.text}</Paragraph>
        <Paragraph>
          <Strong>{customer.accordion5.title2}</Strong>
        </Paragraph>

        <List>{renderListItems(customer.accordion5.list)}</List>
      </TaggedAccordion>
      {backToTop(customer.id)}

      <Heading
        id={income.sectionID}
        size="s5"
        marginBottom="05"
        marginTop="05"
        as="h2"
      >
        {income.title}
      </Heading>

      <TaggedAccordion
        id={income.affordabilityAndSustainability.id}
        label={income.affordabilityAndSustainability.title}
        variation="inpage1"
      >
        {renderTextItems(income.affordabilityAndSustainability.textItems1)}
        <Paragraph>
          <Strong>{income.affordabilityAndSustainability.boldText}</Strong>
        </Paragraph>

        <List>
          {renderListItems(income.affordabilityAndSustainability.list)}
        </List>
        <Paragraph>
          <Strong>
            {income.affordabilityAndSustainability.learnMoreAboutTitle}
          </Strong>
        </Paragraph>
        <Paragraph size="s2" marginBottom="02">
          <Link href={income.affordabilityAndSustainability.taxLink.href}>
            {income.affordabilityAndSustainability.taxLink.label}
          </Link>
        </Paragraph>

        <Paragraph size="s2" marginBottom="02">
          <Link
            href={income.affordabilityAndSustainability.incomeTypesLink.href}
          >
            {income.affordabilityAndSustainability.incomeTypesLink.label}
          </Link>
        </Paragraph>
      </TaggedAccordion>

      <TaggedAccordion
        label={income.applicantsNotPaidInSterling.title}
        id={income.applicantsNotPaidInSterling.id}
        variation="inpage1"
      >
        {renderTextItems(income.applicantsNotPaidInSterling.textItems1)}
      </TaggedAccordion>

      <TaggedAccordion
        id={income.contractors.id}
        label={income.contractors.title}
        variation="inpage1"
      >
        {renderTextItems(income.contractors.textItems1)}
        <Paragraph>
          <Strong>{income.contractors.boldText}</Strong>
        </Paragraph>

        <Paragraph>{income.contractors.text}</Paragraph>
        <Paragraph>
          <Strong>{income.contractors.boldText2}</Strong>
        </Paragraph>

        <Paragraph>{income.contractors.text1}</Paragraph>
        <Paragraph>{income.contractors.text2}</Paragraph>
        <Paragraph>
          <Strong>{income.contractors.boldText3}</Strong>
        </Paragraph>
        <Paragraph>{income.contractors.text4}</Paragraph>
        <Paragraph>
          <Strong>{income.contractors.boldText4}</Strong>
        </Paragraph>
        <Paragraph>{income.contractors.text5}</Paragraph>
        <Paragraph>
          <Strong>{income.contractors.boldText5}</Strong>
        </Paragraph>
        <Paragraph>{income.contractors.text6}</Paragraph>
        <Paragraph>{income.contractors.text7}</Paragraph>
        <Paragraph>
          <Strong>{income.contractors.additionalInfo}</Strong>
        </Paragraph>
        {renderLinkItems(income.contractors.links)}
      </TaggedAccordion>

      <TaggedAccordion
        id={income.incomeTypes.id}
        label={income.incomeTypes.title}
        variation="inpage1"
      >
        <Paragraph>{income.incomeTypes.text1}</Paragraph>
        <List>{renderListItems(income.incomeTypes.list)}</List>
        <Container marginBottom="05">
          <BasicTable {...income.incomeTypes.table} />
        </Container>
        <Paragraph>
          <Strong>{income.incomeTypes.additionalInfo}</Strong>
        </Paragraph>

        {renderLinkItems(income.incomeTypes.links)}
      </TaggedAccordion>

      <TaggedAccordion
        id={income.lengthOfEmployment.id}
        label={income.lengthOfEmployment.title}
        variation="inpage1"
      >
        <Paragraph>{income.lengthOfEmployment.text1}</Paragraph>
        <Paragraph>{income.lengthOfEmployment.text2}</Paragraph>
        <Paragraph>
          <Strong>{income.lengthOfEmployment.additionalInfo}</Strong>
        </Paragraph>
        {renderLinkItems(income.lengthOfEmployment.links)}
      </TaggedAccordion>

      <TaggedAccordion
        id={income.rentalIncome.id}
        label={income.rentalIncome.title}
        variation="inpage1"
      >
        {' '}
        <Paragraph>{income.rentalIncome.textWithLink}</Paragraph>
        <Paragraph>
          <Strong>{income.rentalIncome.boldText}</Strong>
        </Paragraph>
        {renderTextItems(income.rentalIncome.textItems1)}
        <Paragraph>
          <Strong>{income.rentalIncome.boldText2}</Strong>
        </Paragraph>
        <Paragraph>{income.rentalIncome.text2}</Paragraph>
        <Paragraph>
          <Strong>{income.rentalIncome.boldText3}</Strong>
        </Paragraph>
        {renderTextItems(income.rentalIncome.textItems2)}
        <Paragraph>
          <Strong>{income.rentalIncome.additionalInfo}</Strong>
        </Paragraph>
        {renderLinkItems(income.rentalIncome.links)}
      </TaggedAccordion>

      <TaggedAccordion
        id={income.secondJob.id}
        label={income.secondJob.title}
        variation="inpage1"
      >
        <Paragraph>{income.secondJob.text}</Paragraph>
        <List>{renderListItems(income.secondJob.list)}</List>
        <Paragraph>{income.secondJob.text2}</Paragraph>
        <Strong>{income.secondJob.additionalInfo}</Strong>
        {renderLinkItems(income.secondJob.links)}
      </TaggedAccordion>

      <TaggedAccordion
        id={income.selfEmployedApplicants.id}
        label={income.selfEmployedApplicants.title}
        variation="inpage1"
      >
        <Paragraph>{income.selfEmployedApplicants.text1}</Paragraph>
        <Paragraph>
          <Strong>{income.selfEmployedApplicants.boldText1}</Strong>
        </Paragraph>
        <List>{renderListItems(income.selfEmployedApplicants.list)}</List>
        <Paragraph>{income.selfEmployedApplicants.text2}</Paragraph>
        <Paragraph>
          <Strong>{income.selfEmployedApplicants.boldText}</Strong>
        </Paragraph>
        <List>{renderListItems(income.selfEmployedApplicants.list2)}</List>
        <Paragraph>
          <Strong>{income.selfEmployedApplicants.boldText2}</Strong>
        </Paragraph>
        <List>{renderListItems(income.selfEmployedApplicants.list3)}</List>
        <Paragraph>
          <Strong>{income.selfEmployedApplicants.boldText3}</Strong>
        </Paragraph>
        <List>{renderListItems(income.selfEmployedApplicants.list4)}</List>
        <Paragraph>
          <Strong>{income.selfEmployedApplicants.boldText4}</Strong>
        </Paragraph>
        <List>{renderListItems(income.selfEmployedApplicants.list5)}</List>

        {renderTextItems(income.selfEmployedApplicants.textItems)}
        <Paragraph>
          <Strong>{income.selfEmployedApplicants.additionalInfo}</Strong>
        </Paragraph>

        {renderLinkItems(income.selfEmployedApplicants.links)}
      </TaggedAccordion>

      <TaggedAccordion
        id={income.selfEmployedContractors.id}
        label={income.selfEmployedContractors.title}
        variation="inpage1"
      >
        <BasicTable {...income.selfEmployedContractors.table} />
      </TaggedAccordion>

      <TaggedAccordion
        id={income.taxOnRentalIncome.id}
        label={income.taxOnRentalIncome.title}
        variation="inpage1"
      >
        <Paragraph>{income.taxOnRentalIncome.text1}</Paragraph>
        <Paragraph>{income.taxOnRentalIncome.text2}</Paragraph>
        <Paragraph>
          <Strong>{income.taxOnRentalIncome.boldText1}</Strong>
        </Paragraph>
        {renderLinkItems(income.taxOnRentalIncome.links)}
      </TaggedAccordion>
      {backToTop(income.sectionID)}
      <Container marginBottom="none" marginTop="none" padding="none">
        <Heading
          size="s5"
          marginBottom="05"
          marginTop="05"
          as="h2"
          id={lending.id}
        >
          {lending.title}
        </Heading>
        <InfoPanel
          infoPanel={lending.infoPanel}
          pageTestIds={testIds.ourCriteria.lending}
        />
        {backToTop(lending.id)}
      </Container>
      <Container marginBottom="none" marginTop="none" padding="none">
        <Heading
          size="s5"
          marginBottom="05"
          marginTop="05"
          as="h2"
          id={property.id}
        >
          {property.title}
        </Heading>
        <InfoPanel
          infoPanel={property.infoPanel}
          pageTestIds={testIds.ourCriteria.property}
        />
        {backToTop(property.id)}
      </Container>
      <Container marginBottom="none" marginTop="none" padding="none">
        <Heading
          size="s5"
          marginBottom="05"
          marginTop="05"
          as="h2"
          id={existingCustomer.id}
        >
          {existingCustomer.title}
        </Heading>
        <InfoPanel
          infoPanel={existingCustomer.infoPanel}
          pageTestIds={testIds.ourCriteria.existingCustomer}
        />
        {backToTop(existingCustomer.id)}
      </Container>
      <Container marginBottom="none" marginTop="none" padding="none">
        <Heading
          size="s5"
          marginBottom="05"
          marginTop="05"
          as="h2"
          id={topSlicing.id}
        >
          {topSlicing.title}
        </Heading>
        <InfoPanel
          infoPanel={topSlicing.infoPanel}
          pageTestIds={testIds.ourCriteria.topSlicing}
        />
        {backToTop(topSlicing.id)}
      </Container>
    </Main>
  );
}
