import React from 'react';

import { Paragraph, Strong } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import BM_Solutions_Head from 'assets/common/BM_Solutions_Head.png';
import ArticleView from 'client/components/articleView/articleView/ArticleView';
import GradientBox from 'client/components/gradientBox/GradientBox';

import { BuyToLetChangedBMSolutionsChangedWithItContent } from './BuyToLetChangedBMSolutionsChangedWithIt.config';

function BoxComponent({ boxContent }) {
  return (
    <GradientBox>
      <Paragraph inverse data-testid="article-gradient-header">
        <Strong size="s4" inverse>
          {boxContent.paragraphone}
        </Strong>
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-header-1">
        <Strong size="s4" inverse>
          {boxContent.paragraphtwo}
        </Strong>
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-header-2">
        <Strong size="s4" inverse>
          {boxContent.paragraphthree}
        </Strong>
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-text1">
        {boxContent.paragraphfour}
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-text2">
        {boxContent.paragraphfive}
      </Paragraph>
      <Paragraph inverse data-testid="article-gradient-text3">
        {boxContent.paragraphsix}
      </Paragraph>
    </GradientBox>
  );
}

function BuyToLetArticlePage(): React.JSX.Element {
  const articleContent =
    useContent<BuyToLetChangedBMSolutionsChangedWithItContent>();
  return (
    <ArticleView
      content={articleContent}
      imgSrc={BM_Solutions_Head}
      component={() => BoxComponent(articleContent)}
    />
  );
}

export default BuyToLetArticlePage;
